import { IStatus } from '../../../@types/status';
export var initialState = {
    analytics: {
        nbStatClients: 0,
        nbStatClientResponses: 0,
        nbEmployees: 0,
        nbOffices: 0,
        nbKpis: 0,
    },
    nbStatClientResponsesByOffice: [
        {
            nbStatClientResponses: 0,
            office: { _id: '', name: '', address: '', createdAt: undefined, ipAddress: null },
        },
    ],
    status: IStatus.IDLE,
    statusBalance: IStatus.IDLE,
    statusBalancePerMonth: IStatus.IDLE,
    statusKpi: IStatus.IDLE,
    balancePerMonth: [{}],
    balance: {
        totalCashCodes: 0,
        totalDeposit: 0,
        totalCashDeskCurrent: 0,
        totalCashDeskAll: 0,
        totalCredit: 0,
        totalBalance: 0,
        totalCheckCodes: 0,
        checksNumber: 0,
        totalIncomes: 0,
        totalNotPayedCodes: 0,
        totalNotPayedCodesAll: 0,
        totalNotPayedChecksCodes: 0,
        notPayedChecksNumber: 0,
    },
    statsClientResponse: [
        {
            statClient: {},
            offices: [
                {
                    office: {},
                    nbStatClientResponses: 0,
                },
            ],
        },
    ],
    statusStatsClientResponseByMonth: IStatus.IDLE,
    statsClientResponseByMonth: [{}],
    kpiResponse: null,
    statusKpiStatRange: IStatus.IDLE,
    kpiResponseStatRange: null,
    statusExpensesAnalytics: IStatus.IDLE,
    expensesAnalytics: { totalDeposit: 0, totalCredit: 0 },
    cashDesksAnalytics: {
        totalCashDeskCurrent: 0,
        totalCashDeskAll: 0,
    },
    statusCashDesksAnalytics: IStatus.IDLE,
    codesAnalytics: {
        totalCashCodes: 0,
        totalBalance: 0,
        totalCheckCodes: 0,
        checksNumber: 0,
        totalIncomes: 0,
        totalNotPayedCodes: 0,
        totalNotPayedCodesAll: 0,
        totalNotPayedChecksCodes: 0,
        notPayedChecksNumber: 0,
        billsOfExchangeIncomes: 0,
        billsOfExchangeNumber: 0,
    },
    statusCodesAnalytics: IStatus.IDLE,
    statusBooksStockAnalytics: IStatus.IDLE,
    totalBooksAnalytics: null,
    statusGeneralAnalytics: IStatus.IDLE,
    generalAnalytics: [],
    statusRevenueAnalytics: IStatus.IDLE,
    revenueAnalytics: null,
    statusCashDeskPaymentsAnalytics: IStatus.IDLE,
    cashDeskPaymentsAnalytics: null,
    statusBookStocksAnalytics: IStatus.IDLE,
    bookStocksAnalytics: [],
};
